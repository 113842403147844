import { DontHydrate, Link, Logos, PageContainer } from '@loveholidays/design-system';
import React from 'react';

import { FooterUsp } from '@AuroraTypes';
import { DesignSystemAsset } from '@DesignSystemComponents/DesignSystemAsset';
import { Separator } from '@UX/Separator/Separator';

interface FooterLogoProps {
  icon?: string;
}

const FooterLogo: React.FC<FooterLogoProps> = ({ icon }) => (
  <DesignSystemAsset
    type="Logo"
    name={icon as Logos}
    height={['48', '64']}
    svgStyles={{ height: ['48', '64'], maxWidth: '100%' }}
  />
);

interface FooterLogosProps {
  usps?: FooterUsp[];
}

export const FooterLogos: React.FC<FooterLogosProps> = ({ usps }) => {
  if (!usps) {
    return null;
  }

  return (
    <DontHydrate>
      <PageContainer
        sx={{
          textAlign: 'center',
        }}
      >
        <Separator
          lineStyle="solid"
          color="strokeLightneutral"
        />
        <div
          sx={{
            minHeight: '135px',
            paddingTop: ['xl', 'xl', '2xl'],
            paddingBottom: ['xl', 'xl', '2xl'],
          }}
        >
          {usps.map(({ icon, url, target }) => (
            <div
              key={icon}
              sx={{
                color: 'iconDefault',
                marginX: 'xs',
                marginBottom: 0,
                display: 'inline-flex',
              }}
            >
              {url ? (
                <Link
                  href={url}
                  target={target}
                >
                  <FooterLogo icon={icon} />
                </Link>
              ) : (
                <FooterLogo icon={icon} />
              )}
            </div>
          ))}
        </div>
        <Separator
          lineStyle="solid"
          color="strokeLightneutral"
        />
      </PageContainer>
    </DontHydrate>
  );
};
